
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(235 252 255);
  background-attachment: fixed;  */
  /* background: #efefef; */
  background: #e5ecf7;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar-track {
  background: transparent; 
}
::-webkit-scrollbar-thumb {
  background: #768091; 
  border-radius: 4px; 
}
::-webkit-scrollbar-thumb:hover {
  background: #6b7483; 
}
::-webkit-scrollbar {
  width: 8px; 
}
/* .json-preview{  
  scrollbar-width: thin;
  scrollbar-color: #424a5d #656e83;
}
.custom-scrollbar{
  scrollbar-width: thin;
  scrollbar-color: #768091 #fff; 
}
.json-preview .custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #9dabc1 #656e83;
} */
/* .jobs-page .custom-scrollbar, .jobmodal {
  scrollbar-width: thin;
  scrollbar-color: #768091 #fff; 
}
.jobmodal::-webkit-scrollbar {
  width: 5px; 
}
.job-column ::-webkit-scrollbar {
  width: 8px; 
}
.main-content{
  scrollbar-width: thin;
  scrollbar-color: #768091 #e5ecf7; 
} */
@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
  padding: 10px;
  color: #6e6e6e;
}
.react-datepicker__month-text--keyboard-selected{
  background-color: #c2ffc2 !important;
  color: rgb(0, 0, 0) !important;
}
.ql-editor {
  min-height: 150px;
}
.placeholder-class {
  color: #9ca3af;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.react-datepicker__header {
  text-align: center;
  background-color: #fff !important;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.ql-editor{  
  background: #f9fafb;
  
}
.ql-toolbar.ql-snow {
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  border-radius: 10px 10px 0 0;
}
.ql-container.ql-snow {
  border: 1px solid #d1d5db;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  overflow-y: auto;
}

.ql-container{
  max-height: 350px;
}
.start-date-picker .cursor-not-allowed {
  opacity: 0.9;
  color: #fafafb;
}
span.input-preview.first-input {
  font-weight: bold;
  font-size: 14px;
  display: block;
  line-height: 15px;
}

span.input-preview.second-input {
  font-size: 14px;
  display: inline-block;
  line-height: 14px; 
}

/* Modal.css */
.photo-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay */
  z-index: 1000; /* Ensure modal is above other content */
}

.photo-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
  z-index: 1001; /* Ensure content is above the overlay */
}
.cropper {
  position: relative;
  height: 400px; /* Adjust based on your needs */
  margin-top: 20px;
}

.photo-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.photo-footer button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.photo-footer button:hover {
  background-color: #0056b3;
}

.photo-footer input[type="file"] {
  margin-top: 20px;
}
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}
.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: #ffffff !important;
  color: #f0f0f0;
  stroke: #aeaeae;
}

.close {
  float: right;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  right: -6px;
  top: -35px;
}
.dragme {
  cursor: move; /* Adds a move cursor on hover to indicate draggability */
  display: inline-flex; /* Optional: Adjust display to fit your design */
  align-items: center; /* Optional: Center icon vertically */
  justify-content: center; /* Optional: Center icon horizontally */
  padding: 4px; /* Optional: Add some padding around the icon */
}
.react-datepicker-wrapper { 
  width: 100%;
}
input.editable-title-input{
  border-bottom: 1px solid transparent !important;
}
input.editable-title-input:hover {
  border:0px !important;
  border-bottom: 1px solid #d7d7d7 !important;
  outline: 0px;
}
input.editable-title-input:focus-visible {
  border:0px !important;
  border-bottom: 1px solid #d7d7d7 !important;
  outline: 0px;
}

/* Add this to your global.css or within a <style> tag */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom class for the spinner to adjust speed */
.fast-spin {
  animation: spin 0.5s linear infinite;
}

/* Apply to all items within the container managed by Dragula */
.drag-container .section {
  transition: transform 0.2s ease;
}
/* Style for the element being dragged */
.gu-transit {
  /* Example: Reduce opacity and add a shadow for a lifting effect */
  opacity: 0.8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.placeholder {
  height: 50px; /* Adjust based on your item's height */
  background-color: #f0f0f0; /* A light background color for the placeholder */
  border: 2px dashed #ccc; /* A dashed border to indicate it's a placeholder */
  margin: 5px; /* Add some margin if necessary */
  box-sizing: border-box;
}


.custom-dropdown {
  position: relative;
  font-family: 'Arial', sans-serif;
  cursor: pointer;
  width: max-content;
  outline: none;
}

.custom-dropdown-header {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #656e83;
  background-color: #475370;
}
.custom-dropdown-header.active, .custom-dropdown-header:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color:#475370;
}
/* .custom-dropdown-icon {
  margin-right: 10px;
} */

.custom-dropdown-value {
  flex-grow: 1;
}

.custom-dropdown-chevron {
  margin-left: 10px;
}
.custom-dropdown-list {
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width:150px;
  font-size: 14px;
  line-height: 19px;
}

.custom-dropdown-list li {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.custom-dropdown-title {
  font-weight: bold; /* Make the label/title bold */
  margin-right: 10px; /* Add some space between the title and the value */
}
.custom-dropdown-list li:hover {
  background-color: #f2f2f2;
}
.custom-dropdown-list li.active {
  background-color: #f2f2f2;
}
.custom-dropdown-checkmark {
  color: green; /* Use your theme color */
  font-size: 16px;
}
.resume-customize {
  display: flex;
  gap: 10px;
  justify-content: center;
  background: #656e83;
  flex-wrap: wrap;
}
.resume-form {
  display: flex;
  gap: 10px; 
  flex-wrap: wrap;
}
.resume-preview, .coverletter-preview{
  display: flex;
    justify-content: center;
}
.resume-preview-container{
  width: 100%;
  position: relative;
}
.resume-select, .select-color{
  padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    border: 1px solid #656e83;
    background-color: #475370;
}
.resume-select option{
  color: #000;
  background: white;
}
.select-color{
  max-width: 35px;
  padding: 0 2px;
  background-color: #475370;
}
.resume-container .section{
  cursor: pointer;
}

/* Example with plain CSS */
.resume-name:hover .edit-icon {
  visibility: visible;
}
.edit-icon {
  visibility: hidden;
  cursor: pointer;
}
.dash-resume{
  float: left;
  max-width: 242px;
  max-height: 330px;
  overflow: hidden;
}
.dash-resume-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  padding: 10px;
  box-shadow: 0px -3px 20px 20px #33333326;
}
.resume-links{
  font-weight: bold;
  transform: rotate(90deg);
  padding: 0 8px 8px 9px;
  border: 1px solid rgb(201 201 201);
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-end;
  border-radius: 4px;
  margin: 0;
}
.inline-resume-title{
  border-bottom: 2px solid transparent;
  outline: none;
}
.inline-resume-title:hover,
.inline-resume-title:focus{
  border-bottom: 2px solid rgb(196, 196, 196);
  outline: none;
}


.loader {
  width: 60px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,#0681f3 90%,#0084ff00);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}

.download-modal {
  min-height: 150px;
  text-align: center;
  padding: 20px 0;
}
.download-modal h2 {
  font-size: 16px;
  font-weight: 600;
}
.download-modal p{
  
}
.download-modal .mid-wrap {
  min-height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-modal .download-success{
  width: 64px;
  height: 64px;
  fill: #02d122; 
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width:157px;
}
.grabbable {
  cursor: move; 
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.checkmark__circle{stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: #7ac142;
  fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}
  .checkmark{width: 56px;height: 56px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;
    stroke-miterlimit: 10;margin: 10% auto;box-shadow: inset 0px 0px 0px #7ac142;animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}
    .checkmark__check{transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}
    @keyframes stroke{100%{stroke-dashoffset: 0}}@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}
    @keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}}


    .react-datepicker {
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 0.8rem;
      background-color: #fff;
      color: #000;
      border-radius: 1rem !important;
      display: inline-block;
      border:0 !important;
      position: relative;
      line-height: initial;
      padding: 12px 10px 10px;
      box-shadow: 3px 3px 15px 5px #3333331f;
    }
    .react-datepicker__navigation{
      height: 53px !important;
    }
    .react-datepicker__month .react-datepicker__month-text, 
    .react-datepicker__month .react-datepicker__quarter-text {
      display: inline-block;
      width: 4rem;
      margin: 2px;
      font-size: 14px !important; 
  }

  /*Dashboard*/
  .dashboard-container {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 256px; 
    position: fixed;
    color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto; /* in case the content is taller than the screen */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;   
  }
  

  .main-content {
    margin-left: 256px; /* same as the sidebar width */
    flex-grow: 1;
     /* padding: 2.5rem !important;  */
  }
  /* .main-dashboard{
    padding: 2.5rem !important;
  } */
  .resume-updating {
    display: inline-block;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.resume-saving-status {
  width: 34px; 
  color: #737373;
  font-size: 20px;
}
.resume-name input {
  font-size: 14px;
  color: #757575;
  font-weight: 600;
  padding: 5px 5px;
}
.ql-editor ul {
  padding-left: 0 !important;
}
.new-doc {
  display: flex;
  min-width: 240px;
  border: 1px dashed #6e779b;
  justify-content: center;
  color: #717171;
  cursor: pointer;
  border-radius: 5px; 
  min-height: 330px;
}
.new-doc:hover {
  color: #2d2d2d;
  border-color: #969696;
  background: #00000012;
}
.new-doc svg{
  display: block; 
}
.tabs button {
  border-bottom: 2px solid #ffffff00; 
  padding-bottom: 5px;
}
.tabs button.active {
  border-bottom: 2px solid #0cc0df;
  font-weight: 600;
}
.doc-container {
  display:block;
  padding: 50px;
}
.resume-analysis-preview .resume-container{transform: scale(0.82) !important;}

.company-logo{
  width: 67px;
}
.company-details{
  flex-grow: 1;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.apply-now a { 
  border-radius: 5px;
}
.job-search-form{ 
  display: flex;
  gap: 4;
}
span.input-icon {
  top: 11px;
  left: 8px;
  color: #999;
}
.job-input{
  float: left; 
  padding-left:32px;
  min-height: 45px;
}
.job-input:focus-visible {
  outline-color: #f9fdff;
  outline-width: 0;
  background: #f9fdff;
}
.job-search-btn{
  flex-grow: 1; 
  text-align: center;
  max-width: 130px;
  justify-content: center;
}
.saveJob svg {
  fill: #f36262;
}


.interviewWrap {
  width: 100%; 
  margin:0 auto 0;
}

.interviewCard {
  margin-bottom: 25px;
  border-bottom: dashed 1px #d7d7d7;
  padding-bottom: 15px;
}

.interviewCard h3 {
  margin-bottom: 10px;
  font-size: 16px;
}
.interviewCard p {
  font-size: 16px;
  padding: 10px 0;
}
.tip {
  /* background: #f3f3f3;
  border-radius: 10px;
  padding: 14px; */
  font-size: 14px;
  font-style: italic;
  margin: 10px 0;
  color:gray;
}
.resume-data-points ul{
  padding-left: 30px;
}
.resume-data-points ul li{
  list-style: disc;
  margin-bottom: 10px;
}
.resume-data-points ul li:last-child{margin-bottom: 0;}

.resume-strengths > div,
.resume-weaknesses > div,
.resume-improvements > div{  
  padding: 20px 15px;
  border-radius: 6px; 
  font-weight: 500;
}
.resume-strengths > div{background: #dcffdc;  color: #078707;}
.resume-weaknesses > div{background: #ffefef; color: #f35201;}
.resume-improvements > div{background: #e3f3ff; color: #0061c9;}
.resume-data-points.resume-scores ul li {
  font-weight: 500;
}

span.overallScore {
  margin-left: 18px;
  font-size: 25px;
  font-weight: bolder;
  color: #2743b0;
}
.head-sec{
  background-color: #656e83;}
.template-collection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: #e5ecf7;
  gap: 35px;
  height: inherit;
}

.template-item {
  transition: transform 0.2s; 
}

.template-item:hover {
  transform: scale(1.05);
}
.temp-footer strong {
  font-size: 14px;
  font-weight: 600;
  color:#242424;
}
.temp-thumbnail{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}
.active-template .temp-thumbnail{
  position: relative;
}
.active-template .temp-thumbnail img{
  border: 5px solid #1dca00 !important;
}
.selectedTemp{display: none;}
.active-template .selectedTemp {
  display: flex;
  position: absolute;
  bottom: 6px;
  left: 6px;
  right: 6px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  height: 134px;
  padding: 18px;
  background-image: linear-gradient(#ffffff21, white);
}
.active-template .selectedTemp svg{
  color: #1dca00;
  font-size: 45px;
}
.temp-footer.mt-2 {
  position: relative;
  z-index: 3;
}


/* Add this to your CSS file */
.outline-button {
  background-color: transparent;
  border: 2px solid #007bff;
  color: #007bff;
  padding: 5px 20px;
  border-radius: 25px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.outline-button:hover {
  background-color: #007bff;
  color: #fff;
}
.sidebar nav li {
  padding: 0;
}
.sidebar nav{
  margin-top: 10px;
}
.sidebar nav li a {
  padding: 10px;
  font-size: 15px;
  float: left;
  width: 100%;
  color: #2a2929;
  font-weight: 500;
}
.sidebar nav li.active a, .sidebar nav li:hover a {
  background-color: #e5ecf7;
  color: #000000;
  border-radius: 5px;
}
.logo-img{
  max-width: 140px;
}
.cvdesigner-logo{
  padding-left: 0;
  padding-right: 0;
}
.cvdesigner-logo .logo-img {
  max-width: 180px !important;
}

.resumedesign-login .logo-img {
  max-width: 160px;
}
.resumedesign-login {
  margin-top: -75px;
}
.cvdesigner-login .logo-img {
  max-width: 190px  !important;
}
.cvdesigner-login {
  margin-top: -75px;
}
.resumedesign-icon .logo-img {
  max-width: 35px;
}

.loaderBox1 {
  width: 40px;
  height: 40px;
  --c:no-repeat linear-gradient(rgb(8, 122, 216) 0 0);
  background: var(--c),var(--c),var(--c),var(--c);
  background-size: 21px 21px;
  animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l5 {
 0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
 33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
 66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
 100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}
/* HTML: <div class="loader"></div> */
.loaderBox {
  width: 45px;
  aspect-ratio: .75;
  --c:no-repeat linear-gradient(rgb(8, 122, 216) 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  animation: l7 1s infinite linear alternate;
}
@keyframes l7 {
  0%  {background-size: 20% 50% ,20% 50% ,20% 50% }
  20% {background-size: 20% 20% ,20% 50% ,20% 50% }
  40% {background-size: 20% 100%,20% 20% ,20% 50% }
  60% {background-size: 20% 50% ,20% 100%,20% 20% }
  80% {background-size: 20% 50% ,20% 50% ,20% 100%}
  100%{background-size: 20% 50% ,20% 50% ,20% 50% }
}
.company-logos > div {
  background: darkgrey;
}


/* Base button styles */
.ai-writer-footer .popover-menu {
  padding: 6px;
}
.ai-writer-footer {
  min-width: 144px; 
  display: flex;
  justify-content: flex-end;
}

.ai-writer-btn, .ai-btn{
  position: relative;
  background: linear-gradient(45deg, #1493ff, #ab1de5, #3498db, #c538ff);
  background-size: 400% 400%;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 22px;
  transition: transform 0.3s ease;
  overflow: hidden;
  display: flex;
  /* height: 32px; */
  padding: 5px 15px;
  align-items: center;
  gap:5px;
  font-size: 13px !important;
    letter-spacing: 1px;
}
.create-resume-btn{
  font-size: 16px !important;
  height: auto;
  padding: 10px;
}
/* Gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.ai-writer-btn {
  animation: gradientAnimation 3s ease infinite;
}

/* Hover effect */
.ai-writer-btn:hover, .ai-btn:hover {
  transform: scale(1.05);
}

/* Animation effect */
.ai-writer-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 22px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ai-writer-btn:hover::before {
  opacity: 1;
}

/* For animation running */
@keyframes buttonAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.ai-writer-btn:active {
  animation: buttonAnim 0.6s;
}

.load-more-button {
  display: block;
  margin: 27px auto;
  padding: 10px 20px;
  background-color: #cfdbed;
  color: #595959;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.load-more-button:hover {
  background-color: rgb(59 130 246);
  color:#fff;
}


/*--------------------------------------------------*/

.dashboard-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 256px;
  position: fixed;
  color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
}

.main-content {
  margin-left: 256px;
  flex-grow: 1;
  /* padding: 2.5rem !important; */
}
.main-dashboard {
  padding: 2.5rem !important;
}
.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  padding: 0.7rem 1rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: none !important;
}
.fo-btn {
  color: #000;
  font-size: 13px; 
}
.dash-resume:hover {
  box-shadow: 3px 3px 16px 1px #33333347;
}
.footer-mobile{
  display: none;
}
@media (max-width: 768px) {
  body{
    overflow: hidden;
  }
  .footer-mobile{
    display: block;
  }
  .sidebar {
    display: none !important;
  }
  .login-page {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
        justify-content: center;
}
  .login-col-left{
    width: 100% !important;
    display:none !important;
  }
  .login-col-right{
    min-width: 350px !important;
  }
  .dashboard-container{
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    bottom: 50px;
    top: 58px;
    height: calc(100vh - 128px);
  }
  .main-content {
    margin-left: 0;
    padding: 0 0 !important;
  }
  .main-dashboard {
    padding: 0 !important;
  }
  .addSectionsWrap {
    margin-bottom: 80px;
}
  .analysis-preview{
    display: none !important;
  }
  .top-create-btn{
    display: none;
  }
  .job-error{
    display: none !important;
  }
  .templates-tab {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .all-templates {
    display: flex;
    justify-content: center;
  }
  .header {
    display: flex;
  }
  .footer {
    display: flex;
  }
  .resumedesign-logo {
    margin-top: 0px !important;
    }
    .resumedesign-logo .logo-img {
      max-width: 100px !important;
  }
  .res-preview{
    display: none !important;
  }
  .edit-mode{
    display:block !important;
    height: calc(100vh - 75px) !important;
    width: 100% !important;
  }
  .preview-mode{
    display: none !important;
    height: calc(100vh - 75px) !important;
  }
  .resume-container {
    transform: scale(0.50) !important;
}
.dash-resume .resume-container, .dash-resume .coverletter-container {
  transform: scale(0.38) !important;
}
.json-preview .coverletter-container {
  transform: scale(0.50) !important;
}
.resume-container .header-section .personal-photo img {
  width: 104px !important;
  height: 104px !important;
  overflow: hidden !important;
}
.dash-resume {
  float: left;
  max-width: 300px;
  max-height: 385px;
  overflow: hidden;
}
.new-doc {
  display: flex;
  min-width: 300px;
}
.select-resume-template{
  width: 100% !important;
}
.sticky-footer {
  display: flex !important;
}
.templateShowing{
  display: none !important;
}
}

@media (min-width: 769px) {
  .header {
    display: none!important;
  }
  .footer {
    display: none !important;
  }
}

.footer {
  background: #fff;
  color: #000;
  padding: 1rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.footer-nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.footer-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
}

.footer-nav-item.active {
  color: #007bff;
}

.header {
  background: #fff;
  color: #000;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
}

.logo-img {
  max-width: 140px;
}

.cvdesigner-logo {
  padding-left: 0;
  padding-right: 0;
}

.cvdesigner-logo .logo-img {
  max-width: 180px !important;
}

.resumedesign-logo .logo-img {
  max-width: 130px;
}



.loaderBox1 {
  width: 40px;
  height: 40px;
  --c:no-repeat linear-gradient(rgb(8, 122, 216) 0 0);
  background: var(--c),var(--c),var(--c),var(--c);
  background-size: 21px 21px;
  animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
}

.loaderBox {
  width: 45px;
  aspect-ratio: .75;
  --c:no-repeat linear-gradient(rgb(8, 122, 216) 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  animation: l7 1s infinite linear alternate;
}

.sidebar nav li {
  padding: 0;
}
.sidebar nav{
  margin-top: 10px;
}
.sidebar nav li a {
  padding: 10px;
  font-size: 15px;
  float: left;
  width: 100%;
  color: #2a2929;
  font-weight: 500;
}
.sidebar nav li.active a, .sidebar nav li:hover a {
  background-color: #e5ecf7;
  color: #000000;
  border-radius: 5px;
}


.reviewSection{
  margin: 0 0 25px;
}
.reviewSection h3 {
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 2px solid #cfcfcf;
  padding: 0 0 10px;
}
.secTitle{ 
  margin-top: 20px;
  margin-bottom: 10px; 
  border-bottom: 2px solid #cfcfcf;
  padding: 0 0 10px;
  display: flex;
}
.secTitle h3 {
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  border-bottom: none;
  padding: 0;
}
.scoreCard{
  background: #d3e8ff;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 13px;    
    margin-left: 15px;
}
.prose hr {
  margin: 20px 0;
  height: 2px;
  border-color: transparent;
}

.reviewSection ul {
  padding:10px 30px;
}
.prose ul ul {
  padding-bottom: 0;
  padding-top: 6px;
}
.reviewSection ul li {
  list-style: decimal;
  margin: 0px 15px;
  margin-bottom: 10px;
  line-height: 22px;
}
.prose ul li li {
  list-style: none;
  padding: 0;
  margin-top: -5px;
  margin-left: -15px;
  padding-left: 0px;
  margin-bottom: 0 !important;
}
.prose {
  font-size: 15px; 
}
.prose strong {
  font-size: 15px;
  font-weight: 700;
}
.prose p strong {
  font-size: 16px;
  padding: 6px 10px;
}
.ailink{
  position: relative;
  display: flex;
}
.aisign {
  display: none !important;
  position: absolute;
  right: -22px;
  top: 2px;
  font-size: 10px;
  background: #595df3;
  width: 17px;
  color: #fff;
  text-align: center;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}




/*****************---------------------*****************/
/* Add this to your main CSS file or a dedicated file for Job Tracker */
.jobmodal { 
  overflow: hidden;
}
.job-tracker {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px; /* Adds space between columns */
  padding: 20px; 
    overflow-x: auto;
    width: 95%;
    height: 100%;
}

.job-column { 
  border-radius: 8px; 
  flex: 1;
  min-width: 250px; /* Minimum width to ensure columns do not collapse */
  max-width: 300px; /* Maximum width for each column */
  padding: 0;
  display: flex;
  flex-direction: column; 
  gap: 10px; 
  background: #e0e7f1;
  box-shadow: none;
}

.job-column h3 { 
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333;
}
.job-column .job-card:first-child {
  margin-top: -15px !important;
}
.job-card, .search-job-card {
  background-color: #fff; 
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 2px 0px #7e7e7e1f;
}

.job-card:hover, .search-job-card {
  background-color: #ffffff;
}
.search-job-card {
  margin-bottom: 25px;
}
.search-job-details{
  background-color: #fff; 
  border-radius: 15px;
  height: clac(100vh - 150px);
  overflow: auto;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
}
.modalclose{
  margin-top: -55px;
  margin-right: -8px;
}
.jobStatus {
  font-size: 13px;
  background: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 18px;
}
.jobStatus span {
  font-weight: bold;
  margin-left: 5px;
}
.jobStatus select {
  background-color: transparent;
  outline: none;
}

/* svg {
  fill: #176dff;
} */
.jobTrackCoverLetter div#coverletter {
  transform: scale(1) !important;
}
.jobTrackResume .json-preview{
  width:100%;   
  height: 100%;
  background-color: transparent;
}
.jobTrackResume .resume-container, .jobTrackCoverLetter.coverletter-container { 
  transform: scale(1) !important;
}
.jtEditor .ql-editor{
  height: 280px;
}

/* Add this CSS to your global stylesheet or your component stylesheet */
.react-tooltip-lite {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  font-size: 13px;
}

.react-tooltip-lite-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}
.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}


.jobmodal .emailGenerator{
  padding:0 !important;
  margin:0 !important;
}
.jobmodal .emailGenerator h1{ 
 display: none;
}
.jobmodal .jobInterview{
  padding:0 !important;
  margin:0 !important;
  width: 100% !important;
}
.jobmodal .jobInterview h2{
  display: none;
}

.search-job-card.activeCard {
  background: #e6ffef;
}

.sections-container.section-form .sectionDrag {
  font-size: 27px;
  color: #c5c5c5;
  margin: 0;
  padding: 0 1px;
  margin-left: -10px;
}