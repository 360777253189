/* coverLetterStyles.css */
@import url('https://fonts.googleapis.com/css2?family=Playwrite+AR&family=Style+Script&display=swap');
.roboto-regular {
  font-family: 'Roboto', sans-serif;
}
.montserrat-regular {
  font-family: 'Montserrat', sans-serif;
}
.lora-regular {
  font-family: 'Lora', serif;
}
.noto-serif {
  font-family: 'Noto Serif', serif;
}
.overpass-regular {
  font-family: 'Overpass', sans-serif;
}
.quicksand-regular {
  font-family: 'Quicksand', sans-serif;
}
.raleway-regular {
  font-family: 'Raleway', sans-serif;
}
.lato-regular {
  font-family: 'Lato', sans-serif;
}
.rubik-regular {
  font-family: 'Rubik', sans-serif;
}
.poppins-regular {
  font-family: 'Poppins', sans-serif;
}
.inconsolata-regular {
  font-family: 'Inconsolata', monospace;
}
.signika-regular {
  font-family: 'Signika Negative', sans-serif;
}
.ubuntu-regular {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.playwrite-de-grund{
  font-family: "Playwrite DE Grund", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.roboto-mono {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}
.prompt-regular {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.abeezee-regular {
  font-family: "ABeeZee", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lexend {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}


/* The rest of your CSS remains the same */


*{
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, sans-serif;
  height: 100%;
  margin: 0;
  padding: 0; 
  color: #000000;
}
.coverletter-container {
  display: flex;
 flex-wrap: nowrap; 
margin: 0 auto;
position: relative;
width: 800px;
min-width: 800px;
overflow: visible; 
background: #fff; 
min-height: 11.7in !important;
}
#coverletter > div{
  width: 100%;
}
.left-column{
  background-color: #fff;
  flex: 0.9;
}
.right-column {
  flex: 2.1; 
}
.section {
  margin-bottom: 20px;
}
.section-title {
  font-weight: bold;
  margin-bottom: 10px;
}
/* @media (max-width: 600px) {
  .left-column, .right-column {
      flex: 100%;
      max-width: 100%;
  }
} */

.layout-1 .cl-header, .layout-3 .cl-header, .layout-3 .cl-footer {
  text-align: center; 
}

.layout-2, .layout-4 {
  display: flex;
  flex-direction: row;
}

.layout-1 .cl-body, .layout-3 .cl-body {
  display: flex; 
}
.input-section.signature {
  font-family: "Style Script", cursive;
  font-weight: 400;
  font-style: normal;
  margin-top: 12px;
  font-weight: 500; 
  font-size: 18px;
  letter-spacing: 2px;
}
.cl-footer {
  text-align: center;
  margin-top: 20px;
}
.input-section.date {
  font-weight: bold; 
  margin: 8px 0 30px;
  font-style: italic;
  font-size: 12px;
}

.input-section.date-subject-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.input-section.date-subject-wrapper .date-entry {
  font-size: 12px;
  font-weight: bold;
}

.input-section.date-subject-wrapper .subject-entry {
  font-weight: bold;
}


.personal-inline-details, .employer-inline-details {
  display: flex;
  justify-content: start;
  gap: 0;
  font-weight: bold;
}
.personal-inline-details span.inline-detail, .employer-inline-details span.inline-detail{
  margin-right: 5px; 
}
.coverletter-container.professional .cl-header {
  background: #293993;
  height: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coverletter-container.professional .cl-header h2 {
  font-size: 28px;
  line-height: normal;
  font-weight: 600;
  color:#fff;
}
.coverletter-container.professional .cl-body {
  display: flex;
  flex-direction: row-reverse;
  min-height: 80%;
}
.coverletter-container.professional .left-column {
  background-color: transparent;
  color: #000;
  border-left: 2px solid #e6e6e6;
}
.coverletter-container.professional .input-section.date { 
  color: #293993; 
}
.coverletter-container.professional .personal-inline-details span.inline-detail, 
.coverletter-container.professional .employer-inline-details span.inline-detail{
  margin-right: 5px;
  color: #293993;
}

.coverletter-container.modern .flex {
  display: flex;
  flex-direction: row-reverse;
}
.coverletter-container.modern .left-column {
  background: #994731;
}
.coverletter-container.modern .personal-inline-details span.inline-detail, 
.coverletter-container.modern .employer-inline-details span.inline-detail {
  margin-right: 5px;
  color: #fff;
}
.coverletter-container.modern .input-section.date-subject-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 10px;
}
.coverletter-container .left-column {
  display: flex;
  align-content: flex-start;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
.coverletter-container.visionary .cl-body {
  min-height: 80%;
}
.coverletter-container.visionary .cl-header {
  background: #242d3e;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coverletter-container.visionary .cl-header h2 {
  font-size: 28px;
  line-height: normal;
  font-weight: 600;
  color:#fff !important;
}
.coverletter-container.visionary .left-column {
  color: #202020;
  border-right: 1px solid #cccccc;
  background: transparent;
}
.coverletter-container.visionary .section-title {
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 600; 
  letter-spacing: 1.5px;
  border-bottom: 2px solid #242d3e;
  padding: 0 0 10px;
  border-left: none;
  border-right: none;
}


.coverletter-container.creative .cl-header {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  border-bottom: 1px solid #ccc;
}
.coverletter-container.creative .cl-header h2 {
  font-size: 28px;
  line-height: normal;
  font-weight: 600; 
}
.coverletter-container.creative .cl-body {
  display: flex;
  flex-direction: row-reverse;
  min-height: 80%;
}
.coverletter-container.creative .left-column {
  color: #000;
  border-left: 1px solid #cccccc;
}
.coverletter-container.creative .cl-footer{
  display: none;
}

.coverletter-container.impactful .left-column {
  background: #096e65;
  color: #fff;
}
.coverletter-container.impactful .input-section.subject {
  color: #096e65;
}
.coverletter-container.impactful .input-section.date-subject-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}
.coverletter-container.impactful h1 {
  color: black;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 35px;
}
div#coverletter.classic .cl-body {
  min-height: 80%;
}
div#coverletter.bold .cl-body,
div#coverletter.influential .cl-body,
div#coverletter.inspired .cl-body
 {
  min-height: 80%;
}
.coverletter-container.versatile .section-title {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  border-bottom: 1px dashed #ffffff;
  padding: 0 0 5px;
}

.layout-two {
  display: flex;
  flex-direction: column;
  padding: 50px;
  flex-grow: 1;
}
.sub-head {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.sub-head > div {
  width: 200px;
}
.coverletter-container.content-left .input-section.letter-body {
  text-align: left;
}
.coverletter-container.content-right .input-section.letter-body {
  text-align: right;
}
.coverletter-container.content-center .input-section.letter-body {
  text-align: center;
}
.coverletter-container.content-justify .input-section.letter-body {
  text-align: justify;
}

.coverletter-container.executive .cl-header h2 {
  font-size: 28px;
  font-weight: bold;
}
.coverletter-container.executive .personal-inline-details span.inline-detail, 
.coverletter-container.executive .employer-inline-details span.inline-detail{
  color: #000;
}
.coverletter-container.executive .cl-header {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 4px solid #000;
}
.coverletter-container.executive .input-section.date-subject-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}

.coverletter-container.dynamic .sub-head {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  background: #eaeaea;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
  padding-bottom: 0;
}

.coverletter-container.dynamic .cl-header h2 {
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}

.coverletter-container .section-title {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
}


.coverletter-container.explorer .left-column {
  background: #f1f1f1;
  color: black;
}
.coverletter-container.explorer .left-column {
  background: #f1f1f1;
  color: black;
}
.coverletter-container.explorer .cl-header {
  background: #283c50;
  color: #fff;
  line-height: 100px;
  margin-bottom: 30px;
  text-align: center;
}
.coverletter-container.explorer .cl-header h2 {
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  color:#fff;
}
.coverletter-container.explorer .section-title {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  padding: 0 0 4px;
  border-bottom: 2px solid #283c50;
}


.coverletter-container.classic .cl-header {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}
.coverletter-container.classic .cl-header h2 {
  font-size: 24px; 
  text-align: center;
  font-weight: bold; ;
}
.coverletter-container.classic .left-column {
  border-right: 1px solid #ccc;
  color: #000;
}



.coverletter-container.elegant .cl-header {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 50px;
}
.coverletter-container.elegant .cl-header h2 {
  font-size: 24px; 
  text-align: center;
  font-weight: bold; ;
}
.coverletter-container.elegant .left-column {
  border-left: solid 2px #d8d8d8;
  color: #000;
  padding-top: 50px;
}


.coverletter-container.bold .left-column {
  color: #000;
  background: transparent;
}
.coverletter-container.bold .cl-header {
  background: #4b93eb;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coverletter-container.bold .cl-header h2 {
  font-size: 24px; 
  text-align: center;
  font-weight: bold; 
  color: #fff;
}

.coverletter-container.expertise .left-column {
  background: #d3f1ff;
  color: #000;
}

.coverletter-container.ambitious .cl-header {
  background: #ffd3d3;
  padding: 15px;
  font-size: 26px;
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
}
.coverletter-container.ambitious .sub-head {
  border: 2px solid #ffd3d3;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  padding-bottom: 0;
}


.coverletter-container.aspirations h2 {
  font-size: 26px;
  margin-bottom: 30px;
  color:#2196F3;
}
.coverletter-container.aspirations {
  border-left: 30px solid #2196F3;
}

.coverletter-container.minimalist .left-column {
  color: #000;
  border-right: 2px solid black;
}
.coverletter-container.minimalist h2 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 40px;
}
.coverletter-container.minimalist .flex {
  display: flex;
  flex-direction: row-reverse;
}
.coverletter-container.minimalist .input-section.date-subject-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 10px;
}

.coverletter-container.innovative .cl-header {
  padding: 10px 15px;
  background: black;
  margin-bottom: 30px;
  color: #fff;
  font-size: 18px;
}

.coverletter-container.empowering .left-column > div {
  background: #3F51B5;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}
.coverletter-container.empowering .left-column {
  padding-left: 0;
  color: #fff;
}
.coverletter-container.empowering .input-section.date-subject-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 10px;
}
.coverletter-container.empowering h2 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #3f51b5;
}


.coverletter-container.proactive .left-column {
  margin: 20px;
  background: #1f3540;
  border-radius: 25px;
  color: #fff;
}
.coverletter-container.proactive .cl-header {
  border-bottom: 4px solid #1f3540;
  font-size: 24px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.coverletter-container.proactive .right-column {
  padding-right: 0;
}


.coverletter-container.vibrant .cl-header {
  background: #795548;
  margin: 20px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.coverletter-container.vibrant .cl-header h2{
  color: #fff;
  font-size: 26px;
}
.coverletter-container.vibrant .cl-body {
  display: flex;
  flex-direction: row-reverse;
}
.layout-3.coverletter-container.vibrant .left-column {
  color: #000;
}
.layout-3.coverletter-container.vibrant .right-column {
 padding-right: 15px;
}
.coverletter-container.vibrant .section-title {
  border: 1px solid #795548;
  padding: 5px 10px;
  border-radius: 6px;
}


.coverletter-container.influential .cl-header {
  background: #009688;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coverletter-container.influential .cl-header h2{
  color: #fff;
  font-size: 26px;
}
.coverletter-container.influential .left-column{
  color: #000;
  border-right:2px solid #009688;
}
.coverletter-container.influential .section-title {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
  background: #009688;
  padding: 3px 10px;
  border-radius: 5px;
  color: #fff;
}



.coverletter-container.versatile .left-column {
  background: #3F51B5;
}
.coverletter-container.versatile .right-column {
  border-left: #3F51B5 10px solid;
}

.coverletter-container.inspired .cl-header {
  background: #ffdac1;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
}
.coverletter-container.inspired .cl-header h2{
  color: #000;
  font-size: 26px;
}

.layout-3.coverletter-container.inspired .left-column {
  border-right: 2px solid #d2d2d2;
  color: #000;
}

.coverletter-container.versatile .layout-two {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 50px;
  border-left: 5px solid #FFC107;
  padding-left: 30px;
}

.coverletter-container.versatile .cl-header h2 {
  background: #ffc107;
  display: inline-block;
  padding: 7px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
}
.coverletter-container.versatile h1 {
  color: black;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 35px;
}

/****************/
.coverletter-container.proactive .left-column{
  background: var(--proactive-color) !important;
}
.coverletter-container.professional .cl-header{
  background: var(--professional-color) !important;
}

.coverletter-container.executive .cl-header {
  border-color: var(--executive-color) !important;
}

.coverletter-container.modern .left-column {
  background: var(--modern-color) !important;
}

.coverletter-container.visionary .cl-header {
  background: var(--visionary-color) !important;
}
.coverletter-container.visionary .section-title{
  border-color: var(--visionary-color) !important;
}

.coverletter-container.creative .cl-header h2{
  color: var(--creative-color) !important;
}
.coverletter-container.creative .personal-inline-details, 
.coverletter-container.creative .employer-inline-details{
  color: var(--creative-color) !important;
}

.coverletter-container.impactful .left-column{
  background: var(--impactful-color) !important;
}

.coverletter-container.dynamic .cl-header h2,
.coverletter-container.dynamic .input-section.date-subject-wrapper .subject-entry,
.coverletter-container.dynamic .input-section.date-subject-wrapper .date-entry { 
  color: var(--dynamic-color) !important;
}

.coverletter-container.explorer .cl-header{
  background: var(--explorer-color) !important;
}
.coverletter-container.explorer .section-title{
  border-color: var(--explorer-color) !important;
}

.coverletter-container.classic .cl-header h2,
.coverletter-container.classic .input-section.date-subject-wrapper .subject-entry,
.coverletter-container.classic .input-section.date-subject-wrapper .date-entry,
.coverletter-container.classic .personal-inline-details span.inline-detail, 
.coverletter-container.classic .employer-inline-details span.inline-detail { 
  color: var(--classic-color) !important;
}

.coverletter-container.elegant .cl-header h2, 
.coverletter-container.elegant .personal-inline-details span.inline-detail, 
.coverletter-container.elegant .employer-inline-details span.inline-detail { 
  color: var(--elegant-color) !important;
}

.coverletter-container.bold .cl-header {
  background: var(--bold-color) !important;
}
.coverletter-container.bold .personal-inline-details, .coverletter-container.bold .employer-inline-details { 
  color: var(--bold-color) !important;
}

.coverletter-container.expertise .left-column {
  background: var(--expertise-color) !important; 
}

.coverletter-container.aspirations h2 {
  color: var(--aspirations-color) !important;
}
.coverletter-container.aspirations {
  border-color: var(--aspirations-color) !important;
}

.coverletter-container.minimalist .left-column{
  border-color: var(--minimalist-color) !important;
}
.coverletter-container.minimalist .personal-inline-details, 
.coverletter-container.minimalist .employer-inline-details{
  color: var(--minimalist-color) !important;
}

.coverletter-container.influential .cl-header,
.coverletter-container.influential .section-title {
  background:var(--influential-color) !important;
}
.coverletter-container.influential .left-column { 
  border-color: var(--influential-color) !important;
}
.coverletter-container.inspired .cl-header{
  background:var(--inspired-color) !important;
}
.layout-3.coverletter-container.inspired .left-column{
  border-color: var(--inspired-color) !important;
}

.coverletter-container.versatile .layout-two { 
  border-color: var(--versatile-color) !important;
}
.coverletter-container.versatile .cl-header h2 {
  background:var(--versatile-color) !important;
}

.coverletter-container.vibrant .cl-header{
  background:var(--vibrant-color) !important;
}
.coverletter-container.vibrant .section-title{
  border-color: var(--vibrant-color) !important;
}

.coverletter-container.proactive .cl-header {
  border-color: var(--proactive-color) !important;
}
.coverletter-container.proactive .left-column {
  background:var(--proactive-color) !important;
}

.coverletter-container.empowering .left-column > div {
  background:var(--empowering-color) !important;
}
.coverletter-container.empowering h2 {
  color:var(--empowering-color) !important;
}

.coverletter-container.ambitious .cl-header {
  background:var(--ambitious-color) !important;
}
.coverletter-container.ambitious .sub-head {
  border-color: var(--ambitious-color) !important;
}

.coverletter-container.innovative .cl-header{
  background: var(--innovative-color) !important;
}
.coverletter-container.innovative .personal-inline-details, 
.coverletter-container.innovative .employer-inline-details{
  color: var(--innovative-color) !important;
}

.flex {
  display: flex;  
}
/* .coverletter-container.professional .cl-body{ 
  height: calc(100% - 106px) ;
} */
.coverletter-container.modern .left-column { 
  color: #fff;
} 
.coverletter-container.modern .cl-header h2 {
  font-size: 25px;
  margin-bottom: 35px;
}
.coverletter-container.explorer .flex {
  display: flex;
  flex-direction: row-reverse;
}
/* Print Styles */
@media print {
  body, body:after, .coverletter-container{
      background: none;
      width: 100%;
      margin:0;
      padding:0;
  }
  .coverletter-container {
    width: initial;
    max-width: initial;
    box-shadow: none;
    transform: scale(1) !important;
    min-width: initial;  
    width: initial;
  }
  .left-column, .right-column {
    padding: 35px; 
    
  }
 
}
.coverletter-container.bold .left-column {
  border-right: 1px solid #c3c3c3;
}
.coverletter-container.expertise .input-section.date-subject-wrapper .subject-entry{
  font-size: 22px;
}
.coverletter-container .left-column,
.coverletter-container .right-column{
  padding:40px;
}
.coverletter-container .right-column {
  padding-left: 30px;
}
.coverletter-container .left-column {
  padding-right: 30px;
}

.coverletter-container.empowering .right-column,
.coverletter-container.expertise .right-column,
.coverletter-container.elegant .right-column,
.coverletter-container.impactful .right-column,
.coverletter-container.professional .right-column,
.coverletter-container.creative .right-column {
  padding:40px;
  padding-right: 30px;
}
.coverletter-container.empowering .left-column,
.coverletter-container.expertise .left-column,
.coverletter-container.elegant .left-column,
.coverletter-container.impactful .left-column,
.coverletter-container.professional .left-column
.coverletter-container.creative .left-column {
  padding:40px;
  padding-left: 30px;
}

.coverletter-container.proactive .left-column {
  margin-left: 15px;
  padding-left: 30px;
  margin-right: 30px;
}
.coverletter-container.proactive .right-column {
  padding-right: 15px;
  padding-left: 40px;
}
.coverletter-container.empowering .left-column {
  padding-left: 0 !important;
}